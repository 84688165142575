import React, { useMemo } from "react";
import { motion } from 'framer-motion';
import { Container, Heading, Box, Breakpoint } from '@arturpol/style-guide/src/components';
import { Seo } from '../components';
import { getAnimationDuration, getAnimationEasing } from "@arturpol/style-guide/src/js/util";
import blob5 from '../images/blob5.svg';
import { notFound } from '../data/global';

const Template404 = ({ meta, children }) => {

	const blobProps = useMemo(() => {
		
		return {
			style: {
				scale: 0.5,
				rotateZ: 0,
				translateX: -200,
				translateY: -300,
			},
			animate: {
				rotateZ: 360,
			},
			transition: {
				loop: Infinity,
				duration: getAnimationDuration('slowest') * 30,
				ease: getAnimationEasing('linear'),
			},
		};

	}, []);
    
    return (
        <>
            <Seo title={notFound.pageTitle} />
			<Box as="section" className="page-404" style={{ position: 'relative' }} height={{'m': '100vh'}}>
				<Breakpoint m={
					<>
						<img src={blob5} alt="" style={{ position: 'absolute', left: '40%', top: '30vh', width: '40%', height: 'auto' }} />
						<svg width="1102px" height="1091px" viewBox="0 0 1102 1091" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', width: '100%', height: 'auto' }}>
							<title>{notFound.pageTitle}</title>
							<g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.0593859266">
								<g id="des---home---scroll" transform="translate(-87.000000, -48.000000)" fill="#A39A92">
									<g id="bg" transform="translate(-621.000000, -403.000000)">
										<g id="Group-3" transform="translate(969.569571, 988.976437) rotate(23.000000) translate(-969.569571, -988.976437) translate(186.569571, 505.976437)">
											<motion.path id="blob1" {...blobProps} d="M544.917792,5.57262386 C450.568527,21.8218964 363.378987,111.90744 383.850497,205.327524 L383.850497,205.327524 C400.185277,280.16945 474.169102,326.641311 543.857092,358.716422 L543.857092,358.716422 C613.545083,390.791533 690.870113,421.384626 730.381188,487.122725 L730.381188,487.122725 C782.408523,573.873727 748.360053,686.507121 774.453273,784.161544 L774.453273,784.161544 C796.834043,867.736792 866.681138,936.862362 950.582508,958.404557 L950.582508,958.404557 C968.879583,963.115258 987.707008,965.497073 1006.58747,965.70879 L1006.58747,965.70879 L1012.31525,965.70879 C1077.97258,964.967781 1143.57687,938.026805 1189.13394,890.655147 L1189.13394,890.655147 C1262.16313,814.595849 1278.60398,701.591951 1330.31311,609.759743 L1330.31311,609.759743 C1364.57372,548.891133 1413.89627,498.184934 1458.81691,444.620557 L1458.81691,444.620557 C1503.73756,391.109109 1545.68824,332.040092 1560.48501,263.814319 L1560.48501,263.814319 C1596.2306,99.9983637 1446.03548,-1.14938789 1316.04669,97.2989732 L1316.04669,97.2989732 C1142.83438,228.404667 997.041168,159.80839 819.692128,70.0933508 L819.692128,70.0933508 C748.147913,33.88976 669.974323,1.70879034 591.005207,1.70879034 L591.005207,1.70879034 C575.678092,1.70879034 560.297942,2.92616254 544.917792,5.57262386"></motion.path>
										</g>
									</g>
								</g>
							</g>
						</svg>
					</>
				} />
				<Container style={{ paddingTop: '33vh', position: 'relative'}}>
					<Heading importance={1} size={7}>{notFound.title}</Heading>
					<Box marginTop={-2} marginBottom={{'sOnly': 8}}>
						<Heading importance={3} size={5} isAlternative>{notFound.subtitle}</Heading>
					</Box>
				</Container>
			</Box>
        </>
    );

};

export default Template404;
